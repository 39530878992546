body {
    font-family: 'Roboto', sans-serif;
    background-color: #f1f3f4;
    color: #5f6368;
}

.boxModel {
    padding: 15px;
    margin: auto;
    margin-bottom: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px #ccc;
    display: flex;
    flex-direction: column;
}