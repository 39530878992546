.formLogin {
    max-width: 400px;
    width: 100%;
    margin-bottom: 30px;
}

.headerLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerLogin img {
    width: 120px;
    margin-bottom: 0px;
}

.headerLogin p {
    margin-top: 5px;
}

.actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}