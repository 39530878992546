.matrixContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 40px;
}

.top, .bottom {
    display: flex;
    flex-direction: row;
}

.quadrant {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 200px;
    border-bottom: 1px solid #ff0068;
    border-left: 1px solid #ff0068;
}

.quadrant .ratingName {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 23px;
    font-weight: bold;
}

.ratingDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ratingDetails .amount, .ratingDetails .percent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 20px;
}

.ratingDetails .amount {
    color: #ff0068;
    margin-right: 10px;
}

.matrixYAxis {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    height: 405px;
}

.yAxisItemTop, .yAxisItemBottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    width: 100%;
    font-size: 14px;
}
.yAxisItemMiddle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.yAxisItemMiddleTitle {
    display: flex;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    flex-direction: column;
    align-items: center;
}

.yAxisItemMiddleTitle p {
    margin: 1px;
    font-size: 18px;
}

.yAxisItemMiddleValue {
    margin-left: 5px;
    font-weight: bold;
    color: #ff0068;
    font-size: 14px;
}

.matrixXAxis {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30px;
}

.xAxisItemTop, .xAxisItemMiddle, .xAxisItemBottom {
    font-size: 14px;
}

.xAxisItemMiddle, .xAxisItemBottom {
    font-weight: bold;
    color: #ff0068;
}