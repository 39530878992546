.actionsBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 20px;
}

.actionsBar .actionsButtons .button {
    margin-right: 10px;
}

.actionsBar .actionsButtons .datePickerButton {
    margin-top: 10px;
    margin-left: 10px;
}