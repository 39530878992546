.menuLogo {
    height: 100%;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
}

.menuLogo img {
    height: 25px;
}

.menuLogo p {
    margin-top: 2px;
    font-size: 10px;
}

.Mui-selected {
    background-color: rgba(149, 149, 149, 0.133) !important;
    color: rgb(66,133,244) !important;
    border-bottom: 2px solid rgb(66,133,244) !important;
}