body {
    font-family: 'Roboto', sans-serif;
    background-color: #f1f3f4;
    color: #5f6368;
}

.boxModel {
    padding: 15px;
    margin: auto;
    margin-bottom: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px #ccc;
    display: flex;
    flex-direction: column;
}
.menuLogo {
    height: 100%;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
}

.menuLogo img {
    height: 25px;
}

.menuLogo p {
    margin-top: 2px;
    font-size: 10px;
}

.Mui-selected {
    background-color: rgba(149, 149, 149, 0.133) !important;
    color: rgb(66,133,244) !important;
    border-bottom: 2px solid rgb(66,133,244) !important;
}
.formLogin {
    max-width: 400px;
    width: 100%;
    margin-bottom: 30px;
}

.headerLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerLogin img {
    width: 120px;
    margin-bottom: 0px;
}

.headerLogin p {
    margin-top: 5px;
}

.actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
.formRegisterCompany {
    max-width: 600px;
}

.titleRegisterCompany {
    width: 100%;
    text-align: center;
}
.titleConsultCompany {
    width: 100%;
    text-align: center;
}
.formRegisterUser {
    max-width: 600px;
}

.titleRegisterUser {
    width: 100%;
    text-align: center;
}
.titlePartner {
    width: 100%;
    text-align: center;
}

.actionsBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionsBar .registerPartnerButton {
    margin-right: 10px;
}
.matrixContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 40px;
}

.top, .bottom {
    display: flex;
    flex-direction: row;
}

.quadrant {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 200px;
    border-bottom: 1px solid #ff0068;
    border-left: 1px solid #ff0068;
}

.quadrant .ratingName {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 23px;
    font-weight: bold;
}

.ratingDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ratingDetails .amount, .ratingDetails .percent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 20px;
}

.ratingDetails .amount {
    color: #ff0068;
    margin-right: 10px;
}

.matrixYAxis {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    height: 405px;
}

.yAxisItemTop, .yAxisItemBottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    width: 100%;
    font-size: 14px;
}
.yAxisItemMiddle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.yAxisItemMiddleTitle {
    display: flex;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    flex-direction: column;
    align-items: center;
}

.yAxisItemMiddleTitle p {
    margin: 1px;
    font-size: 18px;
}

.yAxisItemMiddleValue {
    margin-left: 5px;
    font-weight: bold;
    color: #ff0068;
    font-size: 14px;
}

.matrixXAxis {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30px;
}

.xAxisItemTop, .xAxisItemMiddle, .xAxisItemBottom {
    font-size: 14px;
}

.xAxisItemMiddle, .xAxisItemBottom {
    font-weight: bold;
    color: #ff0068;
}
.actionsBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 20px;
}

.actionsBar .actionsButtons .button {
    margin-right: 10px;
}

.actionsBar .actionsButtons .datePickerButton {
    margin-top: 10px;
    margin-left: 10px;
}
