.titlePartner {
    width: 100%;
    text-align: center;
}

.actionsBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionsBar .registerPartnerButton {
    margin-right: 10px;
}